(function () {
    'use strict';

    angular.module('imessageModule')
        .controller('iMessageMessageController',
            ['iMessageMessage', 'NgTableParams', '$timeout', 'toastr', 'iServiceConfig', iMessageMessageController]);

    function iMessageMessageController(iMessageMessage, NgTableParams, $timeout, toastr, iServiceConfig) {

        var vm = this;
        vm.tabs = [];
        vm.active = 0;

        vm.removeTab = function (idx) {
            vm.tabs.splice(idx, 1);
        };

        vm.iuserHost = iServiceConfig.iuserHost;
        vm.imessageHost = iServiceConfig.imessageHost;
        vm.getTenantList = vm.iuserHost + '/web/tenant/getList';

        vm.methodOptions = [
            'POST'
        ];

        vm.message = {
            queueTaskChart: {
                dataLimit: 150,
                data: [{
                    name: '当前活跃任务数',
                    datapoints: [{x: 0, y: 0}]
                }],
                config: {
                    title: '队列活跃任务监控',
                    debug: false,
                    showXAxis: true,
                    showYAxis: true,
                    showLegend: true,
                    stack: false,
                    width: 710,
                    height: 350,
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 100,
                        splitNumber: 10,
                        minInterval: 1,
                        maxInterval: 1
                    },
                    xAxis: {
                        type: 'time'
                    }
                }
            },
            activeThreadChart: {
                dataLimit: 100,
                data: [{
                    name: '当前活跃线程数',
                    datapoints: [{x: 0, y: 0}]
                }],
                config: {
                    title: '活跃线程监控',
                    debug: false,
                    showXAxis: true,
                    showYAxis: true,
                    showLegend: true,
                    stack: false,
                    width: 710,
                    height: 350,
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 3,
                        splitNumber: 3,
                        minInterval: 1,
                        maxInterval: 1
                    },
                    xAxis: {
                        type: 'time'
                    }
                }
            },
            wsContent: {
                corePoolSize: 0,
                maxPoolSize: 0,
                activeThreadCount: 0,
                activeTaskCount: 0,
                completedTaskCount: 0,
                queueCapacity: 0
            },
            getTableList: new NgTableParams({count: 10}, {
                getData: function (params) {
                    var data = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        },
                        params.filter()
                    );

                    return iMessageMessage.getMessageList(data,
                        {},
                        function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                        return data.content.datas;
                    });
                }
            }),
            refresh: function () {
                vm.message.getTableList.reload().then(function () {
                    toastr.success("刷新成功");
                }).catch(function () {
                    toastr.error("刷新失败");
                });
            },
            addCallbackParams: function ($event, tab) {
                $event.stopPropagation();
                tab.callbackParams.push({});
            },
            removeCallbackParams: function (tab, idx) {
                tab.callbackParams.splice(idx, 1);
            },
            saveMessage: function (tab, idx, form) {
                var data = tab.data;
                if (data) {
                    data.params = {};
                }
                if (tab.callbackParams && tab.callbackParams.length > 0) {
                    tab.callbackParams.forEach(function (p) {
                        if (p) {
                            data.params[p.key] = p.value;
                        }
                    });
                }

                if (!form.$valid) {
                    form.showMsg = true;
                    toastr.error("请填写完整数据", '错误');
                    return;
                }

                iMessageMessage.saveMessage({}, data, function (resp) {
                    if (resp.status === "SUCCESS") {
                        vm.removeTab(idx);
                        $timeout(function () {
                            vm.message.refresh();
                        }, 0);
                    } else {
                        toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "保存失败！"), "错误");
                    }
                });
            },
            suspendMessage: function (row) {
                var data = {
                    appid: row.appid,
                    bizCode: row.bizCode,
                    runStatus: row.runStatus
                };
                iMessageMessage.suspendMessage({}, data, function () {
                    vm.message.refresh();
                });
            },
            resumeMessage: function (row) {
                var data = {
                    appid: row.appid,
                    bizCode: row.bizCode,
                    runStatus: row.runStatus
                };
                iMessageMessage.resumeMessage({}, data, function () {
                    vm.message.refresh();
                });
            },
            cancelMessage: function (row) {
                var data = {
                    appid: row.appid,
                    bizCode: row.bizCode
                };
                iMessageMessage.cancelMessage({}, data, function () {
                    vm.message.refresh();
                });
            },
            addMessage: function () {
                vm.tabs.push({
                    heading: '新建消息',
                    contentId: 'message-add.html',
                    callbackParams: []
                });
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            },
            showHistoryMessage: function () {
                vm.tabs.push({
                    heading: '历史消息列表',
                    contentId: 'message-history.html'
                });
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            },
            showMonitorView: function () {

                vm.tabs.push({
                    heading: '监控视图',
                    contentId: 'message-monitor.html'
                });

                $timeout(function () {
                    vm.active = vm.tabs.length;
                    var url = "ws://" + vm.imessageHost.replace('https://', '') + "/mq/monitor.ws";
                    var ws = new WebSocket(url);
                    ws.onmessage = function (event) {
                        $timeout(function () {
                            var now = new Date();

                            if (event.data) {

                                vm.message.wsContent = JSON.parse(event.data);

                                vm.message.queueTaskChart.config.yAxis.max = vm.message.wsContent.queueCapacity;
                                vm.message.queueTaskChart.config.yAxis.splitNumber = vm.message.wsContent.queueCapacity / 10;

                                vm.message.activeThreadChart.config.yAxis.max = vm.message.wsContent.maxPoolSize;
                                vm.message.activeThreadChart.config.yAxis.splitNumber = vm.message.wsContent.maxPoolSize;

                                var queueTaskChartData = vm.message.queueTaskChart.data[0];
                                var activeThreadChartData = vm.message.activeThreadChart.data[0];

                                if (queueTaskChartData.datapoints.length >= vm.message.queueTaskChart.dataLimit) {
                                    queueTaskChartData.datapoints.shift();
                                }

                                if (activeThreadChartData.datapoints.length >= vm.message.activeThreadChart.dataLimit) {
                                    activeThreadChartData.datapoints.shift();
                                }

                                var activeTaskCount = vm.message.wsContent.activeTaskCount;
                                var activeThreadCount = vm.message.wsContent.activeThreadCount;

                                queueTaskChartData.datapoints.push({
                                        x: [now.getHours(), now.getMinutes(), now.getSeconds()].join(':'),
                                        y: activeTaskCount
                                    }
                                );

                                activeThreadChartData.datapoints.push({
                                        x: [now.getHours(), now.getMinutes(), now.getSeconds()].join(':'),
                                        y: activeThreadCount
                                    }
                                );
                            }
                        }, 100);
                    };
                }, 0);
            }
        };

        vm.historyMessage = {
            getTableList: new NgTableParams({count: 10}, {
                getData: function (params) {
                    var data = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        },
                        params.filter()
                    );

                    return iMessageMessage.getHistoryMessageList(data,
                        {},
                        function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                        return data.content.datas;
                    });
                }
            }),
            refresh: function () {
                vm.historyMessage.getTableList.reload().then(function () {
                    toastr.success("刷新成功");
                }).catch(function () {
                    toastr.error("刷新失败");
                });
            }
        };
    }
})();

